<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-mobile-table
        ref="tableImpr"
        title="개선 목록"
        :columns="gridImpr.columns"
        :data="ctoInfo.ctoImproveModelList"
        :merge="gridImpr.merge"
        selection="multiple"
        :gridHeightAuto="true"
        rowKey="causesNo"
        :isTitle="true"
        :gridHeight="gridImpr.height"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :editable="editable && !disabled"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="add" /><c-btn
              v-if="!disabled && ctoInfo.ctoImproveModelList.length > 0"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="ctoInfo.ctoImproveModelList"
              mappingType="POST"
              label="저장"
              icon="save"
              @beforeAction="saveImprove"
              @btnCallback="saveCallback" 
            />
            <c-btn v-if="editable && !disabled && ctoInfo.ctoImproveModelList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              :color="item.ibmClassCd === 'IC00000001' ? 'blue-6' : 'orange'"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <!-- <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              /> -->
            </q-chip>
          </template>
          <div v-if="props.row.editFlag!=='C'">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="editable&&!disabled"
                icon="add"
                color="blue-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '1')">
                <q-tooltip>
                  개선요청등록
                </q-tooltip>
              </q-btn>
              <q-btn
                v-if="editable&&!disabled"
                icon="add"
                color="orange"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '2')">
                <q-tooltip>
                  즉시조치등록
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </c-mobile-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup"></div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'cto-cause-prevention',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      standardInfo: {
        sopName: '',
        processName: '',
        ctoDate: '',
        observeUserName: '',
        ctoCompleteFlag: 'N',
      },
      ctoInfo: {
        ctoImproveModelList: [],
      },
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'locationDetail',
            field: 'locationDetail',
            label: '세부위치',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            required: true,
            name: 'findings',
            field: 'findings',
            label: '발견사항',
            align: 'left',
            type: 'text',
            style: 'width:25%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:20%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improveMeasures',
                field: 'improveMeasures',
                label: '개선대책',
                align: 'left',
                style: 'width:40%',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '400px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      completeType: 'PUT',
      completeUrl: 'transactionConfig.sop.cto.basic.update.url',
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      updateUrl: 'transactionConfig.sop.cto.improve.update.url',
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.standardInfo.ctoCompleteFlag) && this.standardInfo.ctoCompleteFlag === 'Y'
    }
  },
  watch: {
    'count'() {
      this.getStdInfo();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.cto.basic.get.url;
      this.getInfoUrl = selectConfig.sop.cto.improve.get.url;
      this.updateUrl = transactionConfig.sop.cto.improve.update.url
      this.deleteUrl = transactionConfig.sop.cto.improve.delete.url
      this.completeUrl = transactionConfig.sop.cto.basic.complete.url

      this.getStdInfo();
      this.getDetail();
    },
    getStdInfo() {
      if (this.popupParam.sopCtoId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.sopCtoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = _result.data;

          this.updateMode = true;
        },);
      }
    },
    getDetail() {
      if (this.popupParam.sopCtoId) {
        this.$http.url = this.$format(this.getInfoUrl, this.popupParam.sopCtoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.ctoInfo.ctoImproveModelList = this.$_.clone(_result.data)
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      let requestContents = props.row.locationDetail;
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.ctoImproveId,
          ibmTaskTypeCd: 'ITT0000060',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.ctoImproveId,
          ibmTaskTypeCd: 'ITT0000060',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getDetail();
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;

      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    saveImprove(type) {
      let isConti = true;
      let checkItem = ['locationDetail', 'findings' ]
      this.$_.forEach(this.ctoInfo.ctoImproveModelList, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [세부위치, 발견사항]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.ctoInfo.regUserId = this.$store.getters.user.userId
            this.ctoInfo.chgUserId = this.$store.getters.user.userId
            
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeCto() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '완료 하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.standardInfo.regUserId = this.$store.getters.user.userId
          this.standardInfo.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getStdInfo();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sop.iim.accident.near.delete.url, this.ctoInfo.sopCtoId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    add() {
      this.ctoInfo.ctoImproveModelList.splice(0, 0, {
        sopCtoId: this.popupParam.sopCtoId,
        causesNo: uuidv4(),
        causeTypeCd: null,
        accidentCause: '',
        improvingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '', 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              this.getDetail();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
