var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-mobile-table",
            {
              ref: "tableImpr",
              attrs: {
                title: "개선 목록",
                columns: _vm.gridImpr.columns,
                data: _vm.ctoInfo.ctoImproveModelList,
                merge: _vm.gridImpr.merge,
                selection: "multiple",
                gridHeightAuto: true,
                rowKey: "causesNo",
                isTitle: true,
                gridHeight: _vm.gridImpr.height,
                usePaging: false,
                filtering: false,
                columnSetting: false,
                editable: _vm.editable && !_vm.disabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      props.row["sopImprovementIds"] &&
                      props.row["ibmStepNames"]
                        ? _vm._l(
                            _vm.$comm.ibmTagItems(props.row, _vm.colorItems),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    color:
                                      item.ibmClassCd === "IC00000001"
                                        ? "blue-6"
                                        : "orange",
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(item, props.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }
                          )
                        : _vm._e(),
                      props.row.editFlag !== "C"
                        ? _c(
                            "div",
                            [
                              _c(
                                "q-btn-group",
                                {
                                  staticClass: "ColumInnerBtnGroup",
                                  attrs: { outline: "" },
                                },
                                [
                                  _vm.editable && !_vm.disabled
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "blue-6",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "1"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 개선요청등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.editable && !_vm.disabled
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "orange",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "2"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 즉시조치등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.add },
                          })
                        : _vm._e(),
                      !_vm.disabled &&
                      _vm.ctoInfo.ctoImproveModelList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.updateUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.ctoInfo.ctoImproveModelList,
                              mappingType: "POST",
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveImprove,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.ctoInfo.ctoImproveModelList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeImpr },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c("div", { staticClass: "popup-bottom-bar-btngroup" }),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }